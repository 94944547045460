import React, { lazy, Suspense } from "react";
import numbro from "numbro";
import esAR from "numbro/languages/es-AR";
import { Switch, Route } from "react-router-dom";

/* Style */
import "./materialize/sass/materialize.scss";

/* Routes */
import PublicRoutes from "./routes/publicRoutes";
import PrivateRoutes from "./routes/privateRoutes";

/* Components */
import Loading from "./components/Loading/Loading";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import AvisoCookies from "./components/AvisoCookies/AvisoCookies";
const Footer = lazy(() => {
  return import(/* webpackChunkName: 'Footer' */ "./components/Footer/Footer");
});
const Header = lazy(() => {
  return import(/* webpackChunkName: 'Header' */ "./components/Header/Header2");
});
const NotFoundPage = lazy(() => {
  return import(
    /* webpackChunkName: 'NotFoundPage' */ "./components/NotFoundPage/NotFoundPage"
  );
});

/* context */
import { EventProvider } from "./context/eventContext";

/* CustomHooks */
import { useAuth } from "./hooks/useAuth";
import { useMobileDetector } from "./components/customHooks/";

numbro.registerLanguage(esAR, true);

export function App() {
	const mobileActive = useMobileDetector();
	const { isInitialized, isLogin } = useAuth();

  const _publicRoutes = PublicRoutes.map((route, index) => {
    let { name, path, exact, subMenu, Component, restricted } = route;

    return Component ? (
      <PublicRoute
        name={name}
        key={index}
        path={path}
        exact={exact}
        isLogin={isLogin}
        subMenu={subMenu}
        component={Component}
        restricted={restricted}
      />
    ) : null;
  });

  const _privateRoutes = PrivateRoutes.map((route, index) => {
    let { path, exact, name, Component } = route;

    return Component ? (
      <PrivateRoute
        key={index}
        path={path}
        name={name}
        exact={exact}
        isLogin={isLogin}
        component={Component}
      />
    ) : null;
  });

  return !isInitialized ? (
    <Loading />
  ) : (
    <Suspense fallback={<Loading />}>
      <EventProvider>
        <Header mobileActive={mobileActive} />
        <Switch>
          {_publicRoutes}
          {_privateRoutes}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </EventProvider>
      <Footer />
      <AvisoCookies />
    </Suspense>
  );
}