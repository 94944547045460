import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { createContext, useReducer } from "react";

import httpClient from "../utils/axios.js";

/**
 * @typedef {Object} selectedEventDate
 * @property {number} funcionId
 * @property {string} nombre
 * @property {[]?} estacionamiento
 * @property {boolean} validatePresaleCode
 * @property {string} presaleCode
 * @property {number} totalOcupado
 * @property {object} cupoEstacionamiento
 * @property {string} hora
 * @property {string} diaNumero
 * @property {string} diaTexto
 * @property {string} mesTextoCorto
 * @property {string} mesTextoCompleto
 **/

/**
 * @typedef {Object} Seat
 * @property {string} id
 * @property {string} ubicacionFila
 * @property {string} ubicacionAsiento
 **/

/**
 * @typedef {Object} Tikects
 * @property {boolean} numbered
 * @property {string} sectorName
 * @property {number} totalSeats
 * @property {Seat[]} seats
 **/

/**
 * @typedef {Object} PurchaseSummary
 * ------------------------
 * Propiedades que se actualizan al llamar a la función recoverOCT
 * ------------------------
 * @property {string} mascaraTarjeta
 * @property {string} nombreTitularTarjeta
 * @property {'string'} respuestaPagoNPS Determina si la OCT ya fue pagada o no:
 * A => Aprobado
 * R => Rechazado
 * ------------------------
 * Propiedades que se actualizan en el componente `Asientos` y en recoverOCT
 * ------------------------
 * @property {boolean} shippingTypeEnabled - Esta propiedad se establece a true cuando ya
 * se a creado correctamente la OCT en el componente `Asientos` o al usar la función
 * recoverOCT y se han seteado los valoes necesarios para ser usados por el componente
 * PurchaseProcess/ShippingTypeForm. También debería cambiar a true cuando se refresca
 * la página (F5) y se recupera la OCT desde el backend o desde cache
 * función recoverOCT
 * @property {number} temporaryPurchaseOrderID
 * @property {number} valorNeto
 * @property {number} cargoTTDE
 * @property {number} sectorId
 * @property {number} promoId
 * @property {Tikects} tikects
 *
 * -----
 * Las siguientes propiedades se setean en el componente PrePurchaseProcess y en recoverOCT
 * -----
 * @property {number} timeLeftEnvio - Valor representado en segundos. Tiempo que le queda
 * al usuario para finalizar el proceso de selección del tipo de envío. Este valor es
 * utilizado en el componente Temporizador y se actualiza tanto en el componente
 * `PrePurchaseProcess` como en la función recoverOCT
 * @property {number} timeLeftPago - Valor representado en segundos. Tiempo que le queda
 * al usuario para finalizar el proceso de pago. Este valor es utilizado en el componente
 * Temporizador y se actualiza tanto en el componente `PrePurchaseProcess` como en la
 *
 * ------
 * Las siguientes propiedades se setean en el componente Asientos
 * ------
 * @property {string} chosenPromotion promocionElegida - Esto seguramente lo utilicemos en
 * un futuro no muy lejano
 * -----------------------------------
 * Las siguientes propiedades se setean en el componente `PurchaseProcess => ShippingTypeForm`
 * -----------------------------------
 * @property {number} cargoEnvio El valor de esta propieda es determinada por diversas
 * variantes que se manejan en pase al tipo de envío seleccionado
 *
 * -----------------------------------
 * Las siguientes propiedades se setean en el componente `PurchaseProcess => FinishPaymentForm`
 * -----------------------------------
 * @property {boolean} startPayment Determina cuando se a iniciado el proceso de pago, es
 * en el momento que el usuario ya a ingresado los datos de la tarjeta y presionó el botón
 * pagar. Esta propiedad es utilizada para saber en que proceso detener el temporizador
 * general que se encarga de cancelar la OCT al vencerse el tiempo.
 * -----------------------------------
 * Las siguientes propiedades aún no se a establecido en que punto se deben de establecer
 * o actualizar
 * -----------------------------------
 * ---
 * @property {string} sucursalCorreo
 * @property {object} estacionamiento
 * @property {string} direccion
 * @property {string} zonaEnvio
 * @property {string} subtipoEnvio
 **/

const initialState = {
  currentEvent: null,
  cacheSvgAsientos: {},
  purchaseSummary: null,
  selectedEventDate: null,
};

const SET_SVG = "SET_SVG";
const SET_EVENT = "SET_EVENT";
const RESET_EVENT = "RESET_EVENT";
const SET_STARTPAYMENT = "SET_STARTPAYMENT";
const SET_PURCHASE_SUMMARY = "SET_PURCHASE_SUMMARY";
const RESET_PURCHASE_SUMMARY = "RESET_PURCHASE_SUMMARY";
const SET_SELECTED_EVENT_DATE = "SET_SELECTED_EVENT_DATE";

const handlers = {
  [SET_STARTPAYMENT]: (state, action) => {
    return {
      ...state,
      startPayment: action.payload.startPayment,
    };
  },
  [SET_EVENT]: (state, action) => {
    return {
      ...state,
      currentEvent: action.payload.currentEvent,
    };
  },
  [SET_PURCHASE_SUMMARY]: (state, action) => {
    return {
      ...state,
      purchaseSummary: {
        ...state.purchaseSummary,
        ...action.payload.purchaseSummary,
      },
    };
  },
  [RESET_PURCHASE_SUMMARY]: (state) => {
    return {
      ...state,
      purchaseSummary: null,
    };
  },
  [SET_SELECTED_EVENT_DATE]: (state, action) => {
    return {
      ...state,
      selectedEventDate: action.payload.selectedEventDate,
    };
  },
  [RESET_EVENT]: (state) => {
    return initialState;
  },
  [SET_SVG]: (state, action) => {
    const { eventID, svg, timestamp } = action.payload;

    return {
      ...state,
      cacheSvgAsientos: {
        ...state.cacheSvgAsientos,
        [eventID]: {
          svg,
          timestamp,
        },
      },
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const EventContext = createContext({
  ...initialState,
  login: () => Promise.reject(),
});

export const EventProvider = (props) => {
  const { children } = props;
  const [recoveringOCT, setRecoveringOCT] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const setEvent = async (eventID) => {
    try {
      const {
        data: { status, data },
      } = await httpClient.apiGetV2(`events/${eventID}`);

      if (!status) return false;

      dispatch({
        type: SET_EVENT,
        payload: {
          currentEvent: data,
        },
      });
    } catch (error) {
      console.error("> error in setEvent:", error);
      return false;
    }

    return true;
  };

  const setStartPayment = (startPayment) => {
    dispatch({
      type: SET_STARTPAYMENT,
      payload: { startPayment },
    });
  };

  const resetEvento = () => dispatch({ type: RESET_EVENT });

  const setPurchaseSummary = (purchaseSummary) => {
    dispatch({ type: SET_PURCHASE_SUMMARY, payload: { purchaseSummary } });
  };
  
  const resetPurchaseSummary = () => {
    dispatch({ type: RESET_PURCHASE_SUMMARY });
  };

  const setSvgAsientos = useCallback(
    async (eventID) => {
      const { cacheSvgAsientos, currentEvent } = state;

      if (currentEvent.estado != "ACTIVO") return;

      try {
        let expiredCache = true;
        const currentSVG = cacheSvgAsientos[eventID];

        if (currentSVG) {
          const currentDate = dayjs();
          const timestamp = dayjs(currentSVG.timestamp);

          expiredCache = timestamp.add(60, "minutes").isBefore(currentDate);
        }

        if (!currentSVG || expiredCache) {
          const {
            data: { status, data },
          } = await httpClient.apiGetV2(`events/svgAsientos/${eventID}`);

          if (!status) return false;

          dispatch({
            type: SET_SVG,
            payload: { eventID, svg: data.svgAsientos, timestamp: +new Date() },
          });
        }
      } catch (error) {
        console.error("> error in setSvgAsientos:", error);
        return false;
      }
    },
    [state]
  );

  const setSelectedEventDate = (selectedEventDate) => {
    dispatch({
      type: SET_SELECTED_EVENT_DATE,
      payload: { selectedEventDate },
    });
  };

  const recoverOCT = async (params) => {
    const { clienteId, eventoId, funcionId, callback = null } = params;
    
    console.log("recoverOCT...");
    setRecoveringOCT(true);
    try {
      const octResponse = await httpClient.apiPostV2(
        "purchase-order/temporary/recovery",
        {
          eventId: eventoId,
          clientId: clienteId,
          functionId: funcionId,
        }
      );
      
      const oct = octResponse.data;

      if (oct.status) {
        let descuentoResponse = await httpClient.apiGet(
          `promo-descuento/${oct.data.promoId}`
        );
        let descuento = (descuentoResponse.data || 0) / 100;
        let valorNeto = oct.data.valorNeto;
        let valorNetoConDescuento = valorNeto - valorNeto * descuento;

        console.log("EVALUAR OCT ANTES DE HACER EL RECOVER:", oct);

        const numbered = !oct.data.noNumerados;

        const tickets = {
          numbered,
          sectorName: oct.data.sectorNombre,
          seats: [],
          totalSeats: oct.data.dataAsientos.length,
        };

        if (numbered) {
          for (const asiento of oct.data.dataAsientos) {
            tickets.seats.push({
              id: asiento.id,
              ubicacionFila: asiento.fila,
              ubicacionAsiento: asiento.butaca,
            });
          }
        }

        dispatch({
          type: SET_PURCHASE_SUMMARY,
          payload: {
            purchaseSummary: {
              tickets,
              promoId: oct.data.promoId,
              sectorId: oct.data.sectorId,
              cargoTTDE: parseFloat(oct.data.cargoTTDE),
              valorNeto: parseFloat(valorNetoConDescuento),
              shippingTypeEnabled: true,
              temporaryPurchaseOrderID: oct.data.ordenDeCompraId,
              timeLeftPago: oct.data.tiempoRestantePago,
              timeLeftEnvio: oct.data.tiempoRestanteTipoEnvio,
              mascaraTarjeta: oct.data.mascaraTarjeta,
              nombreTitularTarjeta: oct.data.nombreTitularTarjeta,
              respuestaPagoNPS: oct.data.respuestaPagoNPS,
            },
          },
        });
      }
      
      if (!oct.status) {
        callback && callback();
      }
    } catch (error) {
      console.error("error in revocerOCT:", error);
      callback && callback();
    }

    setRecoveringOCT(false);
  };

  return (
    <EventContext.Provider
      value={{
        ...state,
        setEvent,
        recoverOCT,
        resetEvento,
        recoveringOCT,
        setSvgAsientos,
        setStartPayment,
        setPurchaseSummary,
        resetPurchaseSummary,
        setSelectedEventDate,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const EventConsumer = EventContext.Consumer;
