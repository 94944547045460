import dayjs from "dayjs";
import React, { useEffect, createContext, useReducer } from "react";

import httpClient from "../utils/axios.js";
import { LOCAL_STORAGE_KEYS } from "../constants/index.js";

const initialState = {
  user: null,
  isLogin: false,
  isInitialized: false,
  triggerOpenLogin: false,
  isLoginLoading: false,
};

const SET_INITIALIZE = "SET_INITIALIZE";
const TRIGGER_OPEN_LOGIN = "TRIGGER_OPEN_LOGIN";

const LOGOUT = "LOGOUT";
const START_LOGIN = "START_LOGIN";
const LOGIN_COMPLETED = "LOGIN_COMPLETED";

const handlers = {
  [SET_INITIALIZE]: (state, action) => {
    const { isLogin, user } = action.payload;

    return {
      ...state,
      user,
      isLogin,
      isInitialized: true,
    };
  },
  [TRIGGER_OPEN_LOGIN]: (state, action) => {
    return {
      ...state,
      triggerOpenLogin: !state.triggerOpenLogin,
    };
  },
  [LOGOUT]: (state) => {
    return {
      ...state,
      user: null,
      isLogin: false,
    };
  },
  [START_LOGIN]: (state) => {
    return {
      ...state,
      isLoginLoading: true,
    };
  },
  [LOGIN_COMPLETED]: (state, action) => {
    const { isLoginLoading, isLogin, user } = action.payload;

    return {
      ...state,
      isLoginLoading,
      isLogin,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialState,
  login: () => Promise.reject(),
  logout: () => Promise.reject(),
  handleOpenLogin: () => {},
  resetErrorLogin: () => {},
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initilize = async () => {
      try {
        const response = await httpClient.apiGetV2("customer/profile");

        const data = response.data.data;
        const status = response.data.status;
        console.log("login data:", data);
        console.log("login status:", status);

        if (status) {
          return dispatch({
            type: SET_INITIALIZE,
            payload: {
              user: data,
              isLogin: true,
            },
          });
        }
      } catch (error) {
        console.log("> error in get profile", error);
      }

      dispatch({
        type: SET_INITIALIZE,
        payload: {
          user: null,
          isLogin: false,
        },
      });
    };

    initilize();
  }, []);

  const login = async ({ email, password }) => {
    dispatch({ type: START_LOGIN });

    const errorMsjGeneral =
      "Lo sentimos! ocurrió un fallo, por favor vuelve a intentar";

    const dataLogin = {
      user: null,
      status: false,
      message: errorMsjGeneral,
    };

    try {
      const response = await httpClient.apiPostV2("auth/signin", {
        email,
        password,
      });

      const data = response.data.data;
      const status = response.data.status;

      if (status) {
        dataLogin.status = true;
        dataLogin.user = data.user;
        sessionStorage.setItem(LOCAL_STORAGE_KEYS.accessToken, data.token);
      }
    } catch (err) {
      const message = err?.response?.data?.message ?? errorMsjGeneral;
      M.toast({
        html: message,
        classes: `black-text yellow`,
      });
    }

    dispatch({
      type: LOGIN_COMPLETED,
      payload: {
        isLoginLoading: false,
        isLogin: dataLogin.status,
        user: dataLogin.status ? dataLogin.user : null,
      },
    });
  };

  const handleOpenLogin = () => {
    dispatch({ type: TRIGGER_OPEN_LOGIN });
  };

  const logout = async () => {
    sessionStorage.removeItem(LOCAL_STORAGE_KEYS.accessToken);
    dispatch({ type: "LOGOUT" });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        handleOpenLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
