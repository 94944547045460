/* Materialize */
export const STYLES = ['large', 'small', 'floating', 'flat'];
export const WAVES = ['light', 'red', 'yellow', 'orange', 'purple', 'green', 'teal'];
export const SIZES = ['s', 'm', 'l', 'xl'];
export const PLACEMENTS = ['left', 'center', 'right'];
export const SCALES = ['big', 'small'];
export const ICON_SIZES = ['tiny', 'small', 'medium', 'large'];

export const LOCAL_STORAGE_KEYS = {
	settings: 'ttde_v01_settings',
	accessToken: 'ttde_v01_accessToken',
};

/* Reducers - Actions */
export const BUYING = 'BUYING';
export const GET_EVENTO = 'GET_EVENTO';
export const SET_EVENTO = 'SET_EVENTO';
export const RESET_EVENTO = 'RESET_EVENTO';
export const STARTING_PURCHASE = 'STARTING_PURCHASE';
export const TOGGLE_CATEGORIES = 'TOGGLE_CATEGORIES';

export const BUY_TICKET_STEP_SEAT = 'asientos';
export const BUY_TICKET_STEP_SHIPPING_TYPE = 'tipo-envio';
export const BUY_TICKET_STEP_COMPLETE_ORDER = 'completar-orden';
export const BUY_TICKET_STEP_SUCCESSFUL_PAYMENT = 'pago-exitoso';