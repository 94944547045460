/* Dependencies */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

/* Components */
import { App } from "./App";
import ErrorBoundary from "./components/ErrorBoundary";
import { AuthProvider } from "./context/authContext";

const Main = (
  <ErrorBoundary>
    <BrowserRouter basename="/">
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </ErrorBoundary>
);

ReactDOM.render(Main, document.getElementById("app"));
